<template>
    <div class="page fill-height">
        <v-container :class="{'d-flexpb-lg-16 px-lg-7 flex-column align-center justify-lg-center fill-height': !isAuthed}">

            <v-card :style="isAuthed ? 'max-width: 625px;' : ''" class="soft-shadow" :class="{'pa-lg-2': isAuthed, 'w-full': phoneOnly}">
                <div class="form-wrapper" :class="{'w-full': isAuthed}">
                    <div class="d-flex justify-center flex-column py-lg-3 mb-2">
                        <v-card-title class="font-weight-semibold pt-0">Contact Us</v-card-title>
                        <v-card-subtitle class="mb-0 pb-0">Need help? Send us a message...</v-card-subtitle>
                    </div>
                    <v-card-text>
                        <form @submit.prevent="submitAction">
                            <template v-if="!isAuthed">
                                <v-text-field hide-details class="mb-4" placeholder="Enter Your Name" label="Name" name="name" v-model="name" :loading="loading" :disabled="loading" outlined></v-text-field>
                                <v-text-field hide-details class="mb-4" placeholder="Enter Your Email" label="Email" name="email" v-model="email" :loading="loading" :disabled="loading" outlined></v-text-field>
                            </template>
                            <v-text-field hide-details class="mb-4" placeholder="Enter Message Subject" label="Subject" name="title" v-model="title" :loading="loading" :disabled="loading" outlined></v-text-field>
                            <v-textarea hide-details class="mb-5" rows="5" no-resize placeholder="Enter Message Details" label="Message" name="content" v-model="content" :loading="loading" :disabled="loading" outlined />
                            <v-btn type="submit" block depressed large :disabled="loading" color="primary" @click.prevent="submitAction">Submit</v-btn>
                        </form>
                    </v-card-text>
                </div>
            </v-card>
        </v-container>

        <slot name="footer" />
    </div>
</template>

<script>

// @ is an alias to /src
import GlobalComputed from '@/helpers/global-computed'
import GlobalMethods from '@/helpers/global-methods'

export default {
    name: 'Help',
    data(){
        return {
            name: '',
            email: '',
            title: '',
            content: '',
            loading: false,

            sentTicket: null,
        };
    },
    components: {
        
    },
    computed:{
        ...GlobalComputed 
    },
    methods: {
        ...GlobalMethods,
        validateEmail(email) {
            const regEx = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return regEx.test(email);
        },

        validate(){
            if (this.title.trim() && this.content.trim() && (this.name && this.email || this.isAuthed)){
                if (this.isAuthed){
                    return {
                        title: this.title,
                        content: this.content,
                    }
                }
                return {
                    name: this.name,
                    email: this.email,
                    title: this.title,
                    content: this.content,
                }
            }else{
                return false;
            }
        },
        reset(){
            this.email = '';
            this.name = '';
            this.title = '';
            this.content = '';
        },
        submitAction(){
            if (!this.loading){
                this.sentTicket = null;
                let data = this.validate();
                if (data){
                    if (this.isAuthed || this.validateEmail(this.email)){
                        this.loading = true;
    
                        this.$store.dispatch('fastPostAction', {url: `/support-tickets`, data}).then((response)=>{
                            let id = response.data.data.id;
                            this.showMsg('Your message sent successfully, Ticket Id: #' + id, 'success');
                            this.loading = false;
                            this.sentTicket = id;
                            this.reset();
                            if (this.isAuthed){
                                window.setTimeout(()=>{
                                    this.$router.push({name: 'inbox'});
                                }, 500);
                            }
                            
                        }).catch(()=>{
                            this.showMsg('Error occurred during sending your request', 'error');
                            this.loading = false;
                        })                    
                    }else{
                        this.showMsg('Email is not valid', 'error');
                    }
                }else{
                    this.showMsg('Inputs are required');
                }
            }
        },
    },
    watch: {
        
    },
    mounted(){
        

    }
}
</script>

<style lang="scss" scoped>

</style>